<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <div class="content">
        <Header
          :meta="meta"
          :page-title="moduleConfig.page_title"
          :page-image="moduleConfig.page_image"
          :page-kv-image="moduleConfig.page_kv_image"
          :header-section="moduleConfig.header_section"
        />
        <Tabs :tabs="tabs" :currentTab="currentTab" v-if="!isIndividualTab" />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import Tabs from "@/components/Page/Liff/Shared/TabsV2";
import themeColor from "@/mixins/liff/themeColor";
import einvoiceMixin from "@/mixins/liff/einvoice";
import _ from "lodash";

export default {
  components: {
    Header,
    Tabs,
  },
  mixins: [themeColor, einvoiceMixin],
  data() {
    return {
      themeConfigPage: "invoice",
    };
  },
  computed: {
    ...mapGetters({
      themeConfig: "liffGeneral/themeConfig",
      meta: "liffGeneral/meta",
      getModule: "liffModule/getModule",
    }),
    moduleConfig() {
      return this.getModule('liff_invoice').module_config || {}
    },
    tabs: function () {
      return _.sortBy(this.moduleConfig.data_source, "order");
    },
    currentTab: function () {
      return this.$route.path.split('/').reverse()[0];
    },
    isIndividualTab () {
      return this.$route.name.match(/.*Tab$/) 
    },
  },
  mounted() {
    if (this.$route.name == 'LiffEinvoice') {
      const isEndWithSlash = this.$route.path.slice(-1) == '/'
      const path = isEndWithSlash ? this.tabs.sort((a,b)=> a.order- b.order )[0].path : `${this.$route.path}/${this.tabs.sort((a,b)=> a.order- b.order )[0].path}`
      this.$router.push({ path })
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
}

.s-container {
  min-height: 100vh;
}

.content {
  padding-bottom: 24px;
}

::v-deep .page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    color:var(--s-primary);
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-bottom: 16px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__button {
    padding: 12px;
  }
}

// .invoice-tab {
//   &--item {
//     border: 1px solid #63636699;
//     padding: 7px 16px;
//     font-weight: 600;
//     font-size: 13px;
//     line-height: 18px;
//     border-radius: 50px;
//     display: inline-flex;
//     margin-right: 9px;
//     &.router-link-exact-active {
//       background: var(--s-primary);
//       border: 1px solid var(--s-primary);
//       color: white !important;
//     }
//   }
// }
</style>
