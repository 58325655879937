

const MODULE_CODE = 'liff_invoice'

export default {
  methods: {
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
  }
}
